
  .contact-form {
    margin: 3rem auto 0;
    max-width: 50%;
    max-width: 350px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    border: 1px solid #18dcff;
    background: #363434;
    padding: 0 30px 10px;
    box-shadow: 0px 4px 10px rgba(51, 51, 51, 0.637);
  }
  .contact_h2 {
    margin-top: 35px;
    text-align: center;
    color: rgb(190, 188, 188);
  }
  .form-content {
    display: grid;
    grid-template-rows: repeat(4, 1fr) 105px;
  }
  .contact_input,
  .contact_textarea {
    background: none;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 1.1rem;
    border: none;
    border-bottom: 1px solid var(--MANDARIN);
    color: #18dcff;
  }
  .contact_input::placeholder, 
  .contact_textarea::placeholder {
   color: rgb(190, 188, 188);
  }
  .contact_input,
  .email-content,
  .contact_textarea {
    width: 90%;
    margin: 0 auto;
  }
  .contact_input {
    padding: 24px 0 12px;
  }
  .email-content {
    position: relative;
  }
  .email-content .contact_input {
    width: 100%;
  }
  #not-mail {
    display: none;
    position: absolute;
    top: 4px;
    color: #ff4d4d;
    right: 0;
    transform-origin: 50% 50%;
  }
  @keyframes dongle {
    0% {
      transform: translate(0px, 0px);
    }
    10% {
      transform: translate(-10px, 0px);
    }
    20% {
      transform: translate(10px, 0px);
    }
    30% {
      transform: translate(-10px, 0px);
    }
    40% {
      transform: translate(10px, 0px);
    }
    50% {
      transform: translate(-10px, 0px);
    }
    60% {
      transform: translate(10px, 0px);
    }
    70% {
      transform: translate(-10px, 0px);
    }
    80% {
      transform: translate(10px, 0px);
    }
    90% {
      transform: translate(-10px, 0px);
    }
    100% {
      transform: translate(0px, 0px);
    }
  }
  .contact_textarea {
    resize: none;
    padding: 24px 0;
  }
  .contact_button {
    font-size: 1.3rem;
    margin-top: 15px;
    color: var(--MANDARIN);
    border: none;
    cursor: pointer;
    transition: .2s;
    width: 100%;
    background: #363434;
  }
  .contact_button:hover {
    letter-spacing: 2px;
  }
  .error::placeholder {
    color: #ff4d4d;
  }
  .form-message {
    margin-top: 10px;
    padding: 12px;
    opacity: 0;
    transition: 0.2s ease;
    color: black;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(51, 51, 51, 0.3);
  }