.enseignesphototable{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;
    width: 60%;   
}

.enseignephotoligne {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 20px;
}

.enseignephotopetite {
    height:150px;
    border: 2px orange solid;
    border-radius: 10px;
    transform: scale(1);
    transition: .3s ease-in-out;

}

.enseignephotopetite:hover {
    cursor: pointer;
    transform: scale(1.15);
    
}

.enseignedemandevis {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

@media (max-width:500px) {

    .enseignesphototable{
        margin-top: 30px;
    }

    .enseignephotoligne{
        margin-top: 50px;
    }

    .enseignephotopetite{
        height:50px;
        margin-left: 5px;
    }
}

