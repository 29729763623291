.h1lettre {
    text-align: center;
    font-size: 1em ;
}


.lineone {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px auto;
    width: 50%;
}

#lettreSuspenduesPharmacie {
    width: 25%;
    height: 25%;
    
}

#lettrePharmacie {
    width: 65%;
    height: 65%;
    
}

.linetwo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px auto;
    width:50%
}

.linetwocolone {
    width: 40%;
    height: 40%;
}

.linetwocolone {
    display: flex;
    flex-direction: column;
}

#lettreretroeclaire {
    width: 80%;
}

.linetwocoloneonelinetwo {
    display: flex;
   
}

#lettresante {
    width: 80%;
    
}

#croixajoure {
    width: 100%;
   
}

.linetwocoloneonelinetwocoloneone {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

#lettreledapparebte {
    width: 60%;
   
}

.linetwocoltwo {
    width: 30%;
    height: 30%;
    
}

#lettrtesBoitier {
    width: 100%;
}

.linetwocolthree {
    width: 22%;
    height: 22%;
}

#detour {
    width: 100%;
}


.lettrephotopetit:hover {
    cursor: pointer;
    opacity: 1;
	-webkit-animation: flash 1.5s;
	animation: flash 1.5s;
}
@-webkit-keyframes flash {
	0% {
		opacity: .4;
	}
	100% {
		opacity: 1;
	}
}
@keyframes flash {
	0% {
		opacity: .4;
	}
	100% {
		opacity: 1;
	}
}

@media (max-width:500px) {

 
    
    .lineone {
        width: 80%;
        margin-top: 60px;
    }

    .linetwo {
        width: 80%;
        margin-top: 20px;
    }

    
    
}