
.quisommenous_divtext {
    display: flex;
    width: 80% ;
    margin: 10px auto;   
}

.quisommenous_h1{
   font-size: 18px;
   text-align: center; 
   font-style: italic;
   font-family: Arial, sans-serif;
}

.quisommenous_images {
    display:flex;
    justify-content: space-around;
    align-items: center;
    width: 60%;
    margin: 30px auto;
}

#atelier1, #atelier2, #atelier3 {
    width: 30%;
}

.quisommesnous_h2 {
    margin-top: 50px;
    font-size: 14px;
    text-align: center; 
    font-style: italic; 
}

@media (max-width:500px) {
  .quisommenous_h1{
      font-size: 10px;
      margin-top: 50px;
  }

  .quisommenous_images{
      width: 80%;
      flex-direction: column;
      margin: auto ;
    
    
  }

    #atelier1 {
    width: 50%;
    margin-bottom: 5px;
}

    #atelier2 {
    width: 50%;
    
}

    #atelier3 {
    display: none;

}
    .quisommesnous_h2 {
        margin-top: 20px;
        font-size: 8px;
    }
}