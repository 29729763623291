.journalField {
    border-radius: 4px;
    display:flex;
}


.carousel-root{
    margin-top: 10px;
    
}
.carousel.carousel-slider {
   left: 50%;
   transform: translate(-50%, 0);
   
}
.carousel .thumbs{
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 10px;
}
.carousel .thumb {
    margin: 0 5px;
    cursor: pointer;
}


