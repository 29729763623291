#croixContainer {
    margin: 0 auto;
    width: 60%;
    max-width: 900px;
}


#croixlineone {
    display:flex;
    justify-content: space-between;
    
}

#croixlinetwo {
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.croixField {
    border-radius: 4px;
    height:80px;
    display:flex;
}

.croixField2 {
    border-radius: 4px;
    display: flex;
}

.croixlegendd {
    font-size: 16px;
    color: rgb(75,75,75);
}

.Bloc {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    
}

.Line {
    display: flex;
    align-items: center;
}

.croixtext {
    padding: 10px 20px;
}

.listCroixrRadio:hover  {
    font-weight: bold;
}
.croix_img:hover {
    cursor: pointer;
}


@media (max-width:500px) {
    

    #croixContainer {
        margin: 0 auto;
        width: 100%;
    }

    #croixlineone {
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 10px;
    }

    .croixField {
        height: 60px;
        width: 140px;
    }

    .croixRadio {
        font-size: 14px ;
    }

    .croixlegendd {
        font-size: 12px;
        margin-right: 5px;
    }

    #listCroixrRadio {
        font-size: 12px;
    }

    .croixtext {
        padding: 0px;
        margin:0px;
        font-size: 14px;
    }

    .croix_img {
        width:95%;
        margin-left: 5px;
    }
}