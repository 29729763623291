body {
    font-family: Arial, sans-serif;
    margin: 30px;
}
h1 {
    text-align: center;
}
p {
    margin-bottom: 10px;
}

