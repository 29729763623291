  
  .menuUL{
      display: flex;
      justify-content: center;
      list-style-type: none;
      background-color: var(--MANDARIN);
      padding :16px;
  }
  
  .menuLi a {
      color: white;
      text-align: center;
      padding: 16px;
      text-decoration: none;
      opacity: 0.8;
    }
  
    .menuLi a:hover {
      background-color: #b71540;
      font-weight: bold;
      font-style: italic;
      opacity: 1;
    }
  
    .active{ /*cette class a été ajouter grace à NavLink*/
      border-bottom:5px solid #eb2f06 ;
    }
  
    .navbar{
      padding-bottom: 0px;
    }
  
    #menu, #close {
      
      display: none;
    }
  
    @media (max-width: 750px) {
  
    }
  
    @media (max-width:500px) {
      
      .menuUL{
        position: fixed;
        top:0;
        left:0;
        background-color: #555;
        opacity: 0.97;
        height: 90vh;
        width: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        transition: 0.5s ease-in-out;
        transform: translateX(-500px);
        z-index: 10;
      }
  
     
  
      .menuLi {
        padding: 20px;
      }
  
      #menu, #close {
        display: block;
        cursor: pointer;
        width : 40px;
        position: fixed;
        top:10px;
        right: 0px;
        z-index:99;
      }
    }