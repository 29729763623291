:root {
    --MELON :#f8c291;
    --MANDARIN :#f29200;
    --TOMATO :#eb2f06;
    --JALAPENO :#b71540; 
    
  }
/*-------------body-----------*/
  body {
    height: 1900px;  
  }
/*-----------Modal-----------*/

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color : rgba(80, 80, 80, 0,75);

}

.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #ccc;
    background: white;
    overflow: auto;
    padding: 20px;
    max-height: 70%;
}

#imageclose {
    position: fixed;
    top : 5px;
    right: 5px;
    border-radius: 4px;
    border: 1px black solid;
    
}

.modal_img {
    width:100%
}  
/*--------------------------------------button demande devis-------------------------------------*/
.btn_demandeDevis {
    position: relative;
    display: inline-block;
    font-weight: bold;
    padding: 12px 0 8px;
    text-decoration: none;
    color: orange;
    transition: .4s;
    cursor: pointer;
}

.btn_demandeDevis:before{
   position: absolute;
   content: '';
   width: 100%;
   height: 4px;
   top:100%;
   left: 0;
   border-radius: 3px;
   background:black;
   transition: .2s;
}

.btn_demandeDevis:after{
   position: absolute;
   content: '';
   width: 100%;
   height: 4px;
   top:0;
   left: 0;
   border-radius: 3px;
   background:black;
   transition: .2s;
}

.btn_demandeDevis:hover:before {
    top: -webkit-calc(100% - 3px);
    top: calc(100% - 3px);
}

.btn_demandeDevis:hover:after {
    top: 3px;
}

/*   ----------------------------------- LOGO -----------------------------------*/

.Accueiltextlogo {
    font-size: 18px;
    text-decoration: none;
    margin-right: 20px;
    color: black;
    
}

.Accueiltextlogo:hover{
    text-decoration: underline;
    color: var(--MANDARIN);
    
}
#logoFirstLine{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 20px;
    padding: 0px;
}

#logo{
  display: flex;
  justify-content: space-around;
  margin-top:10px;
}

#logo-img{
  height: 120px;
}

#logoFirstLinep{
    font-size: 30px;
    font-weight: bold;

}

#logoFirstLinep :hover{
  color:  var(--MANDARIN);
  font-size: 40px;

}
/*---------------------------------------Accueil------------------------------------*/
.h1texte {
    text-align: center;
    width: 60%;
    margin: 0px auto;
    font-size: 1.1em ;
}

.accueillineproduit {
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin: 0 auto;    
}

.accueillineproduitcolone {
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.accueillineproduitobject {
    height:150px;  
    -webkit-transform: scale(1.1);
	transform: scale(1.1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out; 
}

.accueillineproduitobject:hover {
    -webkit-transform: scale(1);
	transform: scale(1);
}
.accueilllientext {
    border: 2px orange solid;
    border-radius: 6px;
    width: 150px;
    color: black;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    margin-top: 4px;
}
/*---------------------------------------footer-------------------------------------*/

    #footerdiv{
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: #303036;
        color: #d3D3D3;
        height: 50px;
        position: fixed;
        left: 0px;
        bottom: 20px;
        width: 100%;
        
    }

    .footercontenu a {
        text-decoration: none;
        color: #d3D3D3;
    }

/*---------------------------------------footer copyright---------------------------*/

    #footercopyrightdiv{
        display: flex;
        justify-content: space-around;
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
        background-color: #343A40;
        color: #686868;
    
    }

    #p2, #p1{
        font-style: italic;
        height: 20px;
        margin: 0px;
        padding : 0px;   
    }

    #p2r, #p1r {
        display: none;
    }
/*--------------------------------------------------------------------------------------Media 850 -----------------------------------------*/

@media (max-width: 850px) {
    body{
        height: 900px;
    }
    /*---------------------------------------footer------------------------------------*/
    #footerdiv{
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: #303036;
        color: #d3D3D3;
        height: 30px;
        left: 0px;
        bottom: 20px;
        width: 100%;
        font-size: 13px;
    }
    /*---------------------------------------footer copyright---------------------------*/
    
        #p2r, #p1r{
            display: block; 
            font-size: 14px;
            height: 20px;
            margin: 0px;
            padding : 0px;  
        }
        #p2, #p1{
            display: none;
        }
}
/*-------------------------------------------------------------------------------------Media 500 ------------------------------------------*/

@media (max-width:500px) {
    body {
        height:750px;
        overflow: auto;
    }

    .h1texte {
        font-size: 8px;
        width: 70%;
        margin-top: 10px;
    }
   
    .Modal {
        top: 25%;
        left: 25%;
        transform: translate(-17%, -15%);
        padding: 5px;  

    }

    #logoFirstLine {
        display: none;
      }
      /*---------------------------------------Accueil------------------------------------*/
   
      .accueillineproduit {
        display: none;
    }
    .slidergeneral {
        display: none;
    }
    
    .sliderAccueil {
        margin-top: 50px;
    }
   
}    
